import * as React from "react";
import HeroAlt from "containers/HeroAlt";
import Layout from "common/components/Layout";
import LetsConnect from "containers/LetsConnect";
import Partnership from "containers/Partnership";
import Seo from "components/seo";
import WhyPartnerWithUs from "containers/WhyPartnerWithUs";

const PartnershipsPage = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="Partnerships" />
      <HeroAlt
        title="Partnerships"
        text="We partner with CPA's and their firms, Financial Advisors, Mortgage Brokers, Insurance Brokers, Insurance Agencies, Insurance Agents and myriad other financial services professions."
      />
      <Partnership />
      <LetsConnect />
    </Layout>
  );
};

export default PartnershipsPage;
